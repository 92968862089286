<template>
  <div id="app">
        <div class="grid">
            <div>
                <h1>图书管理</h1>
                <div class="book">
                <div>
                    <label for="id">
                    编号：
                    </label>
                    <input type="text" id="id" v-model="id" :disabled="flag">
                    <label for="name">
                    名称：
                    </label>
                    <input type="text" id="name" v-model="name">
                    <button @click="addBook">提交</button>
                </div>
            </div>
        </div>
        <div class="total">
            <span>图书总数：</span>
            <span></span>
        </div>
            <table>
                <thead>
                    <tr>
                        <th>编号</th>
                        <th>名称</th>
                        <th>时间</th>
                        <th>操作</th>
                        </tr>
                </thead>
                <tbody>
                    <tr v-for="book in books" :key="book.id">
                        <td >{{book.id}}</td>
                        <td>{{book.name}}</td>
                        <td>{{book.time}}</td>
                        <td>
                            <a href="" @click.prevent="chBook(book)">修改</a>
                            <span>|</span>
                            <a href="" @click.prevent="delBook(book.id)">删除</a>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name:'MyBody',
    data(){
        return {
            id:'',
            name:'',
            flag:false,
            books:[
                {id:'001',name:'智慧人生',time:'2022 10-01'},
                {id:'002',name:'随想录',time:'2022 10-01'},
                {id:'003',name:'激流三部曲',time:'2022 10-01'},
            ]
        }
    },
    methods:{
        
        addBook(){
            if(!this.flag){
                if(this.id==='' || this.name==='') return
                let date = new Date()
                let y = date.getFullYear()
                let m = date.getMonth() + 1
                let d = date.getDay()  
                console.log(y,m,d);

                const timeOne = new Date()
                const year = timeOne.getFullYear()
                let month = timeOne.getMonth() + 1
                let day = timeOne.getDate()

                let time = y + " " + (m<10 ? "0"+m : m) + "-" + (d<10 ? "0"+d : d)
               
                let newBook = {
                    id : this.id,
                    name : this.name,
                    time
                }
                this.books.push(newBook)
                this.id=''
                this.name=''
            }else{
                this.books.forEach(book => {
                    if(this.id == book.id){
                        book.name = this.name
                    }
                });
                this.flag = false
                this.id = ''
                this.name = ''
            }
            
        },
        chBook(book){
            this.flag = true
            this.id = book.id
            this.name = book.name
        },
        delBook(id){
            //方法一
            // for (var i=0; i<this.books.length; i++){
            //     if (id == this.books[i].id){
            //         this.books.splice(i,1)
            //     }
            // }

            //方法二
            this.books = this.books.filter(book =>{
                return id != book.id
            })

        }
    }
}
</script>

<style>
    .grid {
        margin: auto;
        width: 520px;
        text-align: center;
    }
    .grid table {
        width: 100%;
        border-collapse: collapse;
    }
    .grid th,td {
        padding: 10;
        border: 1px dashed orange;
        height: 35px;
        line-height: 35px;
    }
    .grid th {
        background-color: orange;
    }
    .grid .book {
        padding-bottom: 10px;
        padding-top: 5px;
        background-color: #F3DCAB;
    }
    .grid .book button {
        float: right;
        padding-right: 5px;
    } 
    .grid .total {
        height: 30px;
        line-height: 30px;
        background-color: #F3DCAB;
        border-top: 1px solid #C2D89A;
    }
</style>