<template>
  <div id="app">

    <MyFruit/>
    <!-- <MyBookManager/> -->
    
    <footer style="text-align: center;">
        <hr/>
        <a href="http://beian.miit.gov.cn/">
                <font>蜀ICP备2021030222号</font>
        </a>
    </footer>
  </div>
</template>

<script>
import MyFruit from "./components/MyFruit.vue"
import MyBookManager from './components/MyBookManager.vue'
export default {
  name: 'App',
  components: {
    MyBookManager,
    MyFruit
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

  
}
  footer {
    position: absolute;
    bottom: 0; /* 4. 设置页脚position为absolute之后，再将其bottom值设为0，即可使其处于页面的底部 */
    width: 100%;
    /* background-color: #457B9D; */
  }
</style>
